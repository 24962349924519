/* Animation */
.hvr-shutter-out-vertical {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    background: var(--main-color);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-shutter-out-vertical:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: -10px;
    right: -10px;
    background: var(--secondary-color);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-shutter-out-vertical:hover, .hvr-shutter-out-vertical:focus, .hvr-shutter-out-vertical:active,  
  .hvr-shutter-out-vertical-square:hover, .hvr-shutter-out-vertical-square:active, 
  .hrv-shutter-out-vertical-focused{
    color: var(--main-color) !important;
  }
  .hvr-shutter-out-vertical:hover:before, .hvr-shutter-out-vertical:focus:before, .hvr-shutter-out-vertical:active:before, .hrv-shutter-out-vertical-focused:before{
    -webkit-transform: scaleY(1.2);
    transform: scaleY(1.2);
  }

  .hvr-shutter-out-vertical-square {
    display: inline-block;
    vertical-align: text-top;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    background: var(--main-color);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-shutter-out-vertical-square:before {
    left: 0px !important;
    right: 0px !important;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    background: var(--secondary-color);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-shutter-out-vertical-square:hover:before, .hvr-shutter-out-vertical:focus:before, .hvr-shutter-out-vertical-square:active:before {
    -webkit-transform: scaleY(1) !important;
    transform: scaleY(1) !important;
  }