@import 'root.css';


/* Pages */
.d-card-wrapper {
    display: flex;
    flex-flow: row;
    /* flex: 0 1 0; NEW CHANGE*/
    border-radius: 0px;
    background-color: inherit;
    margin: calc(var(--logo-size) / 2 + 15px) 5%;
}
.d-card-wrapper-first {
    border-top: 2px solid var(--secondary-color);
    padding-top: 20px;
}
.d-card {
    border-top: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    
    display: flex;
    flex-flow: row;
    align-items: flex-start;
}

.d-card-img-container {
    /* flex: 1 0 0; */
    display: flex;
    flex-flow: column;
    margin: 10px;
    max-height: 100%;
    width: 100%;
}

.d-card-img {
    width: 100%;
    /* max-height: 100%; */
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    min-height: 48.3%;
} 

.video-wrapper {
    flex: 1 0 0;
    display: inline-block;
    margin-top: 10px;
    position: relative;
    width: 100%;
}

.video-responsive {
overflow: hidden;
padding-bottom: 56.25%;
position: relative;
height: 0;
}

.video-responsive iframe {
left: 0;
top: 0;
height: 100%;
width: 100%;
position: absolute;
}
  
.d-card-img-main {
    width: 100% !important;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.d-card-img-screenshots-container {
    flex: 1 0 0;
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 20px;
    grid-gap: 10px;
    max-width: 80%;
    margin-left: 20%;
}

.d-card-text-container {
    flex: 1 0 40%;
    display: flex;
    flex-flow: column nowrap;
    margin: 10px
}

.d-card-title-container {
    margin: 10px
}
.d-card-tags-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}
.d-card-ul {
    border-left: 3px solid var(--secondary-color);
    padding-left: 10px;
    margin-bottom: 30px;
}

.quick-links-container {
    align-self: flex-end;
}
.exhibitions-container {
    align-self: flex-end;
    /* background-color: var(--secondary-color); */
}
.work-percentage-container {
    margin-bottom: 30px;
    margin-left: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
}
.tag-filter-container {
    padding-top: 5px;
}
.tag-filter-container:hover {
    cursor:pointer;
}

.maxScreenSize {
    max-width: 80vw;
}


@media only screen and (max-width: 800px) {
    .d-card {
        flex-flow: column;
    }
    .d-card-img-container {
        order: 1;
    }
    .d-card-text-container{
        order: 0;
    }
    #body {
        max-height: none;
    }
    .d-card-img-screenshots-container {
        max-width: 100%;
        margin-left: 0%;
    }
    .nav-arrow-container {
        display: none !important;
    }
}
@media only screen and (min-width: 800px){
}
@media only screen and (min-width: 1200px){

    .d-card-wrapper {
        margin-left: 18%;
        margin-right: 18%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

/* Navigation */
.nav-arrow-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
}
.nav-arrow {
    flex-grow: 0;
    margin-left: 10px;
    font-size: 40px;
    color:var(--secondary-color);
    display: flex;
}

  /* Typografie & Layout */
.p {
	color: var(--secondary-color);
	font-family: futura-pt, sans-serif;
	font-size:20px;
	font-style:normal;
	font-variant:normal;
	font-weight:400;
	line-height:1.2;
	margin: 0;
	orphans:1;
	page-break-after:auto;
	page-break-before:auto;
	text-align:left;
	text-decoration:none;
	text-indent:0;
	text-transform:none;
	widows:1;
}   

.m {
    font-style: oblique;
}

.header {
    font-size:40px;
	font-style:normal;
	font-variant:normal;
	font-weight:500;
	line-height:1.2;
	margin-bottom:10px;
}

.p-body {
	margin-bottom:15px;
	margin-left:18px;
	margin-right:0;
	margin-top:0;
}

li.d-card-list {
	line-height:2.4;
    font-weight: 500;
	list-style-position:outside;
	list-style-type:disc;
	margin-bottom:0;
	margin-left:21px;
	margin-right:0;
	margin-top:0;
}
.d-card-list-overview {
	font-size:35px;
 	margin-right:0;
	margin-top:0;        
}

.timeline {
    list-style-type: none;
    position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

.timeline-event::before {
    content: '■';
    display: inline-block;
    position: absolute;
    left: -0.8em;          /* Adjust this value so that it appears where you want. */
}


.timeline-event:not(:last-child):after {
    border-left: 2.5px solid var(--secondary-color);
    display: inline-block;
    content: '';
    position: absolute;
    left: -11px;
    top: 37px;
    height: 100%;
    width: 10px;
}