@import 'root.css';
@import 'animation.css';

/* Logo */
.logo-full-container {
  position: relative;
  width: var(--logo-size-big);
  height: var(--logo-size-big);
  padding-top: 5px;
  align-self: center;
}
.logo-full-container-inner {
  width: calc(100% - 5px);
  height: calc(100% - 5px);
}
.logo-full {
  position: absolute;
  top: 5px;
  left: 0;
  width: calc(var(--logo-size-big) - 10px);
  height: calc(var(--logo-size-big) - 10px);
  filter: brightness(0.7);
  transition: transform 2s ease-in-out;
}
.logo-full-profile {
  filter: brightness(var(--logo-brightness));
}
.logo-full-container:hover .logo-full-outer {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.logo-full-container:hover .logo-full-inner {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.logo-full-outer > * {
  font-weight: bold;
}