@import 'root.css';
@import 'animation.css';

/* Logo */
.logo-container {
  position: relative;
  width: var(--logo-size);
  height: var(--logo-size);
  border-bottom: 5px solid var(--secondary-color);
  border-right: 5px solid var(--secondary-color);
  border-bottom-right-radius: 10px;
  background-color: var(--main-color);
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--logo-size) - 5px);
  height: calc(var(--logo-size) - 5px);
  transition: transform 2s ease-in-out;
}
.logo-container:hover .logo-outer {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.logo-container:hover .logo-inner {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.logo-outer > * {
  font-weight: bold;
}

/* top Navigation */
#topnav-container{
  position: fixed;     /* Fixed topbar (stay in place on scroll and position relative to viewport) */
  z-index: 1;      /* Stay on top of everything */
  overflow: hidden;     /* Disable horizontal scroll */

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

#topnav {
  position: relative;
  flex-grow: 1;
  height: calc(var(--logo-size)/2);

  display: flex;
  overflow-x: hidden;     /* Disable horizontal scroll */
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--main-color);
  border-bottom: 5px solid var(--secondary-color);
}
#topnav-routes-container
{  
  display: flex;
  align-items: flex-start;
}

.nav-item {
  margin-left: var(--topnav-item-margin);
}
a:hover {
  text-decoration: none;
}

#dark-light-button {
  border: none;
  align-self: center;
}

#topnav-social-container{
    display: flex;
    flex-flow: row nowrap;
}
.topnav-social {
  flex: 1 0 0;
  padding: 5px;
  margin: 5px;
  color: var(--secondary-color);
  background-color: var(--main-color);
  display: flex !important;
}

.rotate {
  writing-mode: vertical-lr;
}