/* The Modal (background) */
.modal {
display: none;
flex-flow: column;
position: fixed;
z-index: 1;
padding-top: 10px;
padding-bottom: 10px;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0, 0, 0, 0.493);
}

/* Modal Content */
.modal-content {
    position: relative;
    background: none;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
    border: none;
}

/* The Close Button */
.close-btn {
    align-self: flex-end;
    font-size: 29px;
    color: var(--secondary-color);
    display: flex;
}
.prev, .next {
    align-self: center;
    font-size: 29px;
    color: var(--secondary-color);
    display: flex;
    margin: 5px;
}