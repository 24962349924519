html body {
    min-height: 100vh;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    display: flex;
    flex-flow: column;
}
:root{
    --main-color: #111;
    --secondary-color: #e4e3e3;
    --logo-size: 150px;
    --logo-size-big: 500px;
    --topnav-item-margin: 50px;
    --logo-brightness: 1.3;
    background-color: var(--main-color);
    
    min-height: 100vh; 
}
.d-body {
    min-height: 100vh;

    background-color:var(--main-color);
    color: var(--secondary-color);
    padding: 15px 15px 15px calc(var(--topnav-width) + 15px);

    padding-top: calc(var(--logo-size) /2);
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    max-height: 100%;
}
