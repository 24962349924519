
.markdown {

  }
  .markdown ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
  }
  
  .markdown ul li {
    margin-left: 6em;
    display: list-item;
  }

  .markdown h1 {
    font-size:40px;
	font-style:normal;
	font-variant:normal;
	font-weight:500;
	line-height:1.2;
    margin: 10px 0px 10px 0px
  }

  .markdown p {
	color: var(--secondary-color);
	font-family: futura-pt, sans-serif;
	font-size:20px;
	font-style:normal;
	font-variant:normal;
	font-weight:400;
	line-height:1.2;
	margin: 0;
	orphans:1;
	page-break-after:auto;
	page-break-before:auto;
	text-align:left;
	text-decoration:none;
	text-indent:0;
	text-transform:none;
	widows:1;
    margin-left: 18px
}   

.markdown strong {
    font-style: oblique;
}

.markdown p-body {
	margin-bottom:15px;
	margin-left:18px;
	margin-right:0;
	margin-top:0;
}

.markdown a {
    display: inline-block;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    background: var(--main-color);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.markdown a:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: -10px;
    right: -10px;
    background: var(--secondary-color);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.markdown a:hover, .hvr-shutter-out-vertical:focus, .a:active{
    color: var(--main-color) !important;
}
.markdown a:hover:before, .a:focus:before, .a:active:before{
    -webkit-transform: scaleY(1.2);
    transform: scaleY(1.2);
}